import { getServerRequest, putServerRequest } from '../../helpers/serverRequest';
import { store } from '../../store/store';

const httpURL = store.getters.httpURL;

export async function getUsers() {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios`;
    return await getServerRequest(url, config);
}

export async function getUsusarioByCodigo(codigo) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/siiau/${codigo}`;
    return await getServerRequest(url, config);
}

export async function agregarUsuarioService(data) {
    const token = store.getters.sessionToken;
    const config = { headers: { token } };
    const url = `${httpURL}/usuarios/nuevoUsuario`;
    return await putServerRequest(url, data,config);
}