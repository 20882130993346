<template>
  <v-container>
    <v-card outlined>
      <v-toolbar dense flat
        >Usuarios
        <v-spacer></v-spacer>
        <v-btn icon color="primary" @click="agregarUsuario = true"
          ><v-icon>mdi-plus-circle</v-icon></v-btn
        >
        
      </v-toolbar>
      <!-- <v-card-title>Usuarios</v-card-title> -->
      <v-divider class="mx-3"></v-divider>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-spacer></v-spacer>
        
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :loading="loading"
        :search="search"
        :items="usuariosList"
        class="mx-3"
        :items-per-page="50"
        :footer-props="{
          itemsPerPageOptions: [50, 100, 150, 200, -1],
          itemsPerPageAllText: 'Todos',
          itemsPerPageText: 'Elementos por página',
        }"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(item, index) in items" :key="`item-${index}`">
              <td>{{ item.numero }}</td>
              <td>{{ item.codigo }}</td>
              <td>{{ item.usuarioMaster.tipo }}</td>
              <td>
                <v-icon v-if="item.admin" small color="orange darken-2">mdi-star</v-icon>
                {{ item.usuarioMaster.nombre }}
              </td>
              <td> {{ item.usuarioMaster.correo }} </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:page-text="{}">
          sdfghjkl
        </template>
      </v-data-table>
    </v-card>
    <agregarUsuarioComponent
      v-if="agregarUsuario"
      :mostrar="agregarUsuario"
      @cancelar="agregarUsuario = false"
      @usuarioAgregado="usuarioAgregado"
    />
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getUsers } from "./usuarios.service";

import agregarUsuarioComponent from "./agregarUsuario.vue";
export default {
  name: "usuariosView",
  components: { agregarUsuarioComponent },
  computed: {
    ...mapGetters(["userAdmin"]),
    usuariosList() {
      const usuarios = [];
      let contador = 1;
      for (const usuario of this.usuarios) {
        const nuevoUsuario = { numero: contador++, ...usuario };
        usuarios.push(nuevoUsuario);
      }
      return usuarios;
    },
  },
  async mounted() {
    if (!this.userAdmin) this.$router.push("/sesiones");
    this.cargarUsuarios();
  },
  data: () => ({
    loading: false,
    search: "",
    headers: [
      {
        text: "#",
        align: "start",
        sortable: false,
        value: "numero",
      },
      { text: "Código", value: "codigo" },
      { text: "Tipo", value: "usuarioMaster.tipo" },
      { text: "Nombre", value: "usuarioMaster.nombre" },
      { text: "Correo", value: "usuarioMaster.correo" },
    ],
    usuarios: [],
    agregarUsuario: false,
  }),
  methods: {
    async cargarUsuarios() {
      try {
        this.loading = true;
        const serverResponse = await getUsers();
        this.loading = false;
        if (!serverResponse.ok) throw new Error(serverResponse.mensaje);
        this.usuarios = serverResponse.usuarios;
      } catch (error) {
        this.loading = false;
        this.$notificarError(error);
      }
    },
    usuarioAgregado(data) {
      this.usuarios.push(data.usuario);
      this.agregarUsuario = false;
    },
  },
};
</script>

<style>
.disable-events {
  pointer-events: none;
  opacity: 0.2;
}
</style>